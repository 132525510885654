// Header
.header {
    background: @gray-900;
    color: @white;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-bottom: 3rem;

    &.only-logo {
        margin-bottom: 0;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
    }

    .ant-typography {
        color: @white;
    }

    .ant-input {
        width: 15.5rem;
        &-affix-wrapper {
            margin-right: 0.5rem;
        }
    }

    &-logo {
        display: inline-block;
        line-height: 0;
        color: white;
        max-width: 7rem;

        svg {
            width: 100%;
            height: 100%;
        }
    }

    &-nav {
        display: flex;
        align-items: center;
        margin: 0 0 0 1rem;

        &_item {
            font-weight: 500;
            font-size: 1rem;
            &:not(:first-child) {
                margin-left: 0.25rem;
            }
        }

        a {
            padding: 0.5rem 0.75rem;
            display: inline-block;
            border-radius: 0.375rem;
            color: @gray-100;
            transition: 0.3s all;
            &:hover {
                color: @white;
                background: darken(@gray-700, 5);
            }
            &.active {
                background: @gray-700;
            }
        }
    }

    &-icon {
        padding: 0.625rem;
        color: @white;
        display: inherit;
        border-radius: 0.375rem;
        transition: 0.3s all;
        background: transparent;
        border: none;
        cursor: pointer;
        &:hover {
            color: @white;
            background: darken(@gray-700, 5);
        }
        &.active {
            background: @gray-700;
        }
    }

    &-avatar {
        display: flex;
        align-items: center;
        margin-right: 1.5rem;
        font-size: @font-size-sm;
        img {
            width: 2rem;
            height: 2rem;
            border-radius: 50%;
            object-fit: cover;
        }
    }

    &-lang {
        &.ant-select,
        &.ant-select:not(.ant-select-customize-input) {
            margin-right: 1rem;
            .ant-select-selector {
                border-radius: 0.5rem;
                background: transparent;
                color: @white;
                border: 1px solid @white;
                box-shadow: @main-shadow;
                height: 2.375rem;
                padding: 0 0.5rem;
                .ant-select-selection-search-input {
                    height: auto;
                }
                .ant-select-selection-item {
                    display: flex;
                    align-items: center;
                    line-height: normal;
                    padding-right: 1.25rem;
                    font-size: @font-size-sm;
                    font-weight: 500;
                    svg {
                        width: 1.25rem;
                        height: 1.25rem;
                    }
                }
            }
            .ant-select-arrow {
                width: 1.25rem;
                height: 1.25rem;
                margin-top: 0;
                transform: translateY(-50%);
                vertical-align: unset;
                line-height: normal;
                right: 0.5rem;
                svg {
                    color: @white;
                }
            }

            &.ant-select-focused:not(.ant-select-disabled),
            &:hover {
                .ant-select-selector {
                    border-color: @white;
                    box-shadow: @main-shadow;
                    background: darken(@gray-700, 5);
                }
            }
            &.ant-select-open .ant-select-selection-item {
                color: @white;
            }
        }
    }
}
.ant-select-dropdown.header-lang_dropdown {
    border-radius: 0.5rem;
    padding: 0;
    .ant-select-item {
        font-size: @font-size-sm;
        min-height: 2.5rem;
        &:hover {
            background: @gray-50;
        }
        &-option-selected:not(.ant-select-item-option-disabled) {
            background: @primary-50;
        }
        &-option-content svg {
            width: 1.25rem;
            height: 1.25rem;
        }
    }
}
