// CARDS
.ant-card {
    border: 1px solid @gray-300;
    box-shadow: 0px 6px 12px -6px rgba(#18274b, 0.12), 0px 8px 24px -4px rgba(#18274b, 0.08);

    &.card-login  {
        margin: 0 auto;
        width: 100%;
        max-width: 32.5rem;
        &.resetPassword {
            width: 35%;
            .ant-btn-text {
                display: block;
                margin-left: auto;
                margin-right: auto;
            }
        }
        .ant-card-body {
            padding: 4.25rem 3.5rem;
            .ant-form-item:last-child {
                margin-bottom: 0;
            }
            button[type='submit'] {
                width: 100%;
                font-size: 1rem;
                height: 2.75rem;
            }

            h3.ant-typography {
                font-weight: 600;
            }
        }

        .desktop({
            width: 60%;
            &.resetPassword {
                width: 50%;
            }
        });

        .tablet({
            width: 80%;
            &.resetPassword {
                width: 70%;
            }
        });

        .mobile({
            width: 90%;
            &.resetPassword {
                width: 85%;
            }
            .ant-card-body {
                padding: 2rem 1.5rem;
            }
        });
    }

    &-small {
        .ant-card-body {
            padding: 0.5rem;
        }
    }
}
