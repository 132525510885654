// TIMELINE
.ant-timeline {
    &-item {
        display: flex;
        align-items: flex-start;
        width: 100%;
        &-head {
            width: 1.5rem;
            height: 1.5rem;
            border-color: @gray-300;
            position: relative;
            &-custom {
                top: 0;
                left: 0;
                transform: unset;
                padding: 0;
            }
        }
        &-content {
            top: 0;
            display: flex;
            align-items: flex-start;
        }
        &-tail {
            top: 0;
            height: 100%;
            left: 11px;
            border-left-style: dashed;
        }

        &-last {
            padding-bottom: 0;
            .ant-timeline-item-content {
                min-height: unset;
            }
            .ant-timeline-item-tail {
                display: block;
            }
        }
        &:first-child {
            padding-top: 1rem;
        }
    }

    .custom-dot {
        width: 1.5rem;
        height: 1.5rem;
        background: @primary-color;
        border-radius: 50%;
        color: @white;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .event-completed {
        .ant-timeline-item-tail {
            &::before {
                content: '';
                width: 2px;
                height: 100%;
                position: absolute;
                top: 0;
                left: -2px;
                background: @primary-color;
            }
        }
    }
}
