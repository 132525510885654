.ant-form,
.form {
    .ant-form-item {
        align-items: stretch;
        flex-flow: column wrap;
        margin-bottom: 1.25rem;
        &-label {
            display: block;
            text-align: left;
            line-height: normal;

            & > label {
                color: @gray-700;
                font-size: @font-size-sm;
                font-weight: 500;
                height: auto;
                margin-bottom: 0.375rem;

                &::after {
                    display: none;
                }
            }
        }
        &-explain-error {
            font-size: 0.75rem;
            margin: 0;
            padding: 0;
            min-height: unset;
        }
        &-has-error {
            &:not(.ant-input-disabled):not(.ant-input-borderless) .ant-input:focus,
            &:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless)
                .ant-input-affix-wrapper-focused {
                border-right-width: 1px !important;
                border-color: @error !important;
            }
        }
        .ant-form-item-explain {
            padding-top: 0.5rem;
        }
    }

    .ant-input-affix-wrapper {
        padding: 0.75rem;
        border-radius: 0.5rem;
        .ant-input {
            border-radius: 0;
        }
    }

    .ant-input {
        border-radius: 0.5rem;
        padding: 0.625rem 0.875rem;
        border: 1px solid @gray-300 !important;
        line-height: 1.5rem;
        box-shadow: @main-shadow;
        &:not(:disabled):hover {
            background: transparent;
            border-color: @gray-300;
            box-shadow: none;
        }
        &:not(:disabled):focus {
            background: transparent;
            border-color: @primary-color !important;
            box-shadow: @shadow-primary-focused;
        }
    }

    input[type='radio']:focus {
        outline: none;
    }

    // SELECT
    .ant-select,
    .ant-select:not(.ant-select-customize-input) {
        &:hover .ant-select-selector {
            border-color: @gray-300;
        }
        font-size: 1rem;
        height: 2.75rem;
        &.ant-select-focused {
            &:not(&.ant-select-disabled) .ant-select-selector {
                box-shadow: @shadow-primary-focused;
                border-color: @primary-color;
            }
        }
        .ant-select-selector {
            border-radius: 0.5rem;
            border: 1px solid @gray-300;
            box-shadow: @main-shadow;
            padding: 0 0.875rem;
            height: 2.75rem;
            color: @gray-900;
            background: @white;
            display: flex;
            align-items: center;
            &::after {
                display: none;
            }
            .ant-select-selection-search {
                left: 0.875rem;
                right: 0.875rem;
                height: 2.75rem;
                .ant-select-selection-search-input {
                    height: 100%;
                }
            }
        }
        .ant-select-arrow {
            right: 1rem;
            width: 1.25rem;
            height: 1.25rem;
            margin: 0;
            transform: translateY(-50%);
        }
        .ant-select-selection-item {
            line-height: 1.5rem;
            padding-right: 0;
        }
        .ant-select-selection-placeholder {
            line-height: normal;
            display: flex;
            align-items: center;
            height: 100%;
        }
        &.ant-select-multiple {
            .ant-select-selection-overflow {
                overflow-x: auto;
                flex-wrap: nowrap;
            }
            .ant-select-selector {
                border-radius: 0.5rem;
                background: @white;
                border-color: @gray-300;
                padding: 0;
            }
            .ant-select-selection-item {
                height: auto;
                font-size: @font-size-sm;
                border-radius: 0.25rem;
                margin-left: 0.875rem;
                margin-right: 0;
                padding: 0 0.25rem;
                &-remove {
                    margin-left: 0.25rem;
                    vertical-align: unset;
                    svg {
                        width: 0.5rem;
                        height: 0.5rem;
                    }
                }
            }
        }
    }
}

div.ant-select-dropdown {
    border-radius: 0.5rem;
    padding: 0;
    box-shadow: @dropdown-shadow;

    .ant-select-item-option {
        padding: 0.625rem 0.875rem;
        align-items: center;
        line-height: normal;
        .ant-select-item-option-content {
            display: flex;
            align-items: center;
            position: relative;
        }
        &-active:not(.ant-select-item-option-disabled) {
            background: @primary-25;
            font-weight: 400;
        }
        &-selected:not(.ant-select-item-option-disabled) {
            background: @primary-25;
            font-weight: 400;
            .ant-select-dropdown-icon {
                opacity: 1;
            }
        }
    }

    .ant-select-dropdown-icon {
        position: absolute;
        top: 50%;
        right: 1rem;
        color: @primary-color;
        transform: translateY(-50%);
        opacity: 0;
        transition: 0.3s;
    }
}

.form-messageError {
    text-align: center;
    color: @error-color;
    margin-top: 0.25rem;
    font-weight: 500;
}

.form {
    &-body {
        padding: 0;
    }
    &-date {
        .ant-row.ant-form-item {
            margin-bottom: 1.5rem;
        }
    }
    &-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 2rem;
        .ant-btn {
            width: calc(50% - 0.75rem);
            height: 2.75rem;
        }
    }
    &-label {
        display: block;
        line-height: normal;
        font-size: 0.75rem;
        margin-bottom: 0.5rem;
    }
    &-input {
        &:not(:last-child) {
            margin-bottom: 1rem;
        }
    }
    &-radio {
        position: relative;
        text-align: center;
        line-height: normal;
        font-weight: 600;
        color: @secondary-color;
        label {
            font-size: 1rem;
            display: block;
            padding: 1.5rem;
            border-radius: 0.5rem;
            border: 1px solid @secondary-color;
            pointer-events: none;
            transition: 0.3s all;
        }
        input {
            cursor: pointer;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            appearance: none;
            &:checked ~ label {
                color: @primary-color;
                background: @tertiary-color;
                border-color: @tertiary-color;
            }
        }
    }
    .ant-select {
        width: 100%;
    }

    .mobile({
        padding: 0 1.5rem 1.5rem;
        &-radio {
            label {
                padding: 1rem;
            }
        }
        &-footer {
            margin-top: 0;
        }
    });
}

// ANT PICKER
.ant-picker {
    border-color: @gray-300;
    padding: 0.625rem 0.875rem;
    border-radius: 0.5rem;
    width: 100%;
    transition: 0.3s all;
    input {
        color: @gray-900;
        font-size: 1rem;
        border-radius: 0;
        &::placeholder {
            color: @gray-500;
        }
    }
    &:hover,
    &:focus,
    &-focused {
        border-color: @gray-300;
        background: transparent;
        box-shadow: none;
    }
    &-suffix {
        color: @gray-500;
        svg {
            width: 1.25rem;
            height: 1.25rem;
        }
    }
    &-ranges {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.5rem 0.875rem;
    }
}

.ant-picker-dropdown {
    max-width: 100%;

    .ant-picker-panel-container {
        border-radius: 0.5rem;
    }

    .mobile({
        .ant-picker-time-panel-column {
            width: 2.2rem;
            li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
                padding-left: 0.5rem;
            }
        }

        .ant-picker-date-panel {
            width: 260px;
        }

        .ant-picker-body {
            padding-right: 0.5rem;
            padding-left: 0.5rem;
        }
    });
}

.input-error {
    .ant-input {
        border-color: @error-color;

        &:not(:disabled):focus,
        &:not(:disabled):hover {
            border-color: @error-color-hover;
        }
    }
}

// INPUTS
.ant-input {
    border-radius: 0.5rem;
    box-shadow: @main-shadow;
    padding: calc(0.625rem - 1px) 0.875rem;
    line-height: 1.5rem;
    &[disabled] {
        background: @white;
        opacity: 0.7;
    }
    &:focus {
        box-shadow: @shadow-primary-focused;
    }
}

.ant-input-affix-wrapper {
    border-radius: 0.5rem;
    padding: calc(0.625rem - 1px) 0.875rem;
    line-height: 1.5rem;
    box-shadow: @main-shadow;
    .ant-input {
        box-shadow: none;
        border-radius: 0;
        &-prefix {
            color: @gray-500;
            margin-right: 0.5rem;
        }
    }
}

.ant-input-group-wrapper {
    .ant-input-group-addon {
        border-top-left-radius: 0.5rem;
        border-bottom-left-radius: 0.5rem;
        background: @white;
        padding: 0 0.875rem;
        height: 2.75rem;
        line-height: 1.5rem;
        .ant-select {
            margin: 0;
            border: none;
            &.ant-select-focused {
                .ant-select-selector {
                    color: @gray-900;
                    border: none;
                    box-shadow: none;
                }
            }
        }
        .ant-select-selector {
            padding: 0;
            height: auto;
            display: flex;
            align-items: center;
        }
        .ant-select-selection-item {
            line-height: normal;
        }
    }
}
