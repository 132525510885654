// TAG
.ant-tag.ant-tag {
    border: none;
    padding: 0.125rem 0.5rem;
    font-weight: 500;
    margin: 0;
    &-lg {
        font-size: 0.875rem;
        padding: 0.25rem 0.75rem;
        line-height: 1.25rem;
    }
    &-orange {
        background-color: @orange-50;
        color: @orange;
    }
    &-success {
        background-color: lighten(@success, 70%);
        color: @success;
    }
    &-error {
        background-color: @error-50;
        color: @error;
    }
}

// RADIO GROUP
.ant-radio-group {
    display: flex;
    align-items: center;
    border: 1px solid @gray-300;
    border-radius: 0.5rem;
    box-shadow: @main-shadow;
    .ant-radio-button-wrapper {
        background: @white;
        display: flex;
        align-items: center;
        justify-content: center;
        color: @gray-700;
        font-size: @font-size-sm;
        font-weight: 500;
        border: none;
        padding: 0 1rem;
        border-radius: 0;
        line-height: 1rem;
        &::before {
            left: 0;
            background-color: @gray-300;
        }
        &:hover {
            color: inherit;
            &::before {
                background-color: @gray-300;
            }
        }
        &:focus-within {
            box-shadow: none;
        }
        &-checked {
            background: @gray-100;
        }
        &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
        }
        &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
        }
    }
}

// TABS
.ant-tabs {
    color: @gray-800;
    .ant-tabs-nav {
        margin: 1.5rem 0 0;
        flex-direction: column;
        &::before {
            display: none;
        }
        &::after {
            content: '';
            background: @gray-200;
            width: 100%;
            height: 1px;
            display: block;
            margin: 1.5rem 0 2rem;
            position: relative;
            z-index: -1;
        }
        &-list {
            border: 1px solid @gray-300;
            border-radius: 0.5rem;
        }
    }
    &-ink-bar {
        display: none;
    }
    &-tab {
        padding: 0.625rem 1rem;
        font-weight: 500;
        line-height: normal;

        &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
        }

        &:nth-last-child(2) {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
        }

        &:not(:first-child) {
            border-left: 1px solid @gray-300;
        }
        &-active {
            background: @gray-50;
            .svg {
                color: @gray-800 !important;
            }
        }
        & + .ant-tabs-tab {
            margin: 0;
        }

        .row .svg {
            margin-right: 0.5rem;
            svg {
                width: 1rem;
                height: 1rem;
                transition: 0.5s;
            }
        }
    }
}

// BREADCRUMB
.ant-breadcrumb {
    span:last-child {
        font-weight: 600;
    }
}

// 404
.error-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    max-width: 60rem;

    .ant-typography {
        font-weight: 600;
        &:is(span) {
            color: @primary-color;
            display: inline-block;
        }
    }

    .error-text {
        font-size: 1.25rem;
    }
}

// User page
.username {
    margin-bottom: 0;
    color: #072561;
    font-weight: 700;
    font-size: 2rem;
    line-height: 1.23;
}

// Dropdown search
.dropdown-search {
    &_noResults {
        pointer-events: none;
        font-weight: 400;
        text-align: center;
        color: @gray-500;
    }
}

// Grid exchange
.grid {
    padding-bottom: 3rem;
    &-header {
        margin-bottom: 1.5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .mobile({ 
            flex-direction: column;
        });
        &_search {
            width: 20rem;
            .mobile({
                margin-top: 1rem;
                width: 100%;
            });
        }
    }
    &-tab-icon {
        margin-right: 0.5rem;
        display: inline-block;
    }

    &--single {
        border: 1px solid @gray-200;
        box-shadow: @secondary-shadow;
        border-radius: 0.5rem;
        padding-bottom: 0;
        .ant-table-wrapper {
            border: none;
            box-shadow: none;
            border-radius: 0;
        }
        .grid-header {
            padding: 1.25rem 1.5rem;
            margin-bottom: 0;
            border-bottom: 1px solid @gray-200;
        }
    }
}

// DROPDOWN
.ant-dropdown {
    padding-top: 0;
    border-radius: 0.5rem;
    box-shadow: @dropdown-shadow;
    background: @white;
    &-content {
        &-item {
            cursor: pointer;
            transition: 0.3s all;
            &:hover {
                background-color: @primary-25;
            }
            &:first-child {
                border-top-right-radius: 0.5rem;
                border-top-left-radius: 0.5rem;
            }
            &:last-child {
                border-bottom-right-radius: 0.5rem;
                border-bottom-left-radius: 0.5rem;
            }
        }
    }

    .ant-btn-link {
        color: @gray-700;
        font-weight: 400;
        padding: 0.625rem 1rem;
    }
}

// Event Pages
.static {
    &-hero {
        padding-top: 6rem;
        padding-bottom: 6rem;
        .ant-typography {
            max-width: 56.25rem;
            &.ant-typography-secondary {
                font-size: 1.25rem;
                .mobile({
                    font-size: 1.125rem;
                });
            }
        }
        .mobile({
            padding-top: 3rem;
            padding-bottom: 3rem;
        });
    }
    &-section {
        margin: 3rem 0;
        .ant-typography {
            max-width: 56.25rem;
            &.ant-typography-secondary {
                font-size: 1.25rem;
                .mobile({
                    font-size: 1.125rem;
                });
            }
        }
        h2.ant-typography {
            font-size: 2.25rem;
            .mobile({
                font-size: 1.875rem;
            });
        }
        .ant-divider {
            margin-top: 3rem;
            margin-bottom: 0;
        }
        &:last-child {
            display: inline-block;
            margin-top: 0;
        }
        &.centered {
            height: calc(100vh - 70px); // Height header
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
        }
        .ant-btn,
        a {
            .mobile({
                width: 100%;
            });
        }
        .mobile({
            margin: 2rem 0;
        });
    }
    &-phone {
        background: @primary-50;
        display: inline-block;
        padding: 1.5rem 1.5rem 3rem;
        color: @primary-700;
        .ant-typography {
            color: @primary-900;
            margin-top: 4rem;
            font-weight: 500;
            font-size: 1.25em;
        }
        a {
            color: @primary-700;
            font-size: 1rem;
            margin-top: 1.25rem;
            display: inline-block;
        }
        & ~ .ant-divider {
            margin: 3rem 0;
            .mobile({
                margin: 2rem 0;
            });
        }
        .ant-btn {
            .mobile({
                width: auto;
            });
        }
    }
}

// ANT PROGRESS
.ant-progress {
    &-inner {
        background: @primary-50;
        border-radius: 0.5rem;
    }
    &-bg {
        background: @primary-color;
    }
    &-text {
        font-size: @font-size-sm;
        margin-left: 0.75rem;
        font-weight: 500;
        color: @gray-700;
    }
    &-status-success {
        .ant-progress-bg {
            background: @primary-color;
        }
        .ant-progress-text {
            color: @gray-700;
        }
    }
}

// PAGE CONTAINER
.pageContainer {
    &-header {
        .ant-typography.ant-typography-secondary {
            font-size: 1rem;
        }
    }
    &-separator {
        &::before {
            content: '';
            background: @gray-200;
            width: 100%;
            height: 1px;
            display: block;
            margin: 1.5rem 0 2rem;
            position: relative;
            z-index: -1;
        }
    }
}

// EXPIRED PAGE
.expired-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(100vh - 5.375rem);
    max-width: 60rem;
}
