// TYPOGRAPHY
.ant-typography {
    display: block;
    strong {
        font-weight: 500;
    }
    &.ant-typography-secondary {
        color: @gray-500;
        font-size: @font-size-sm;
    }
    &.title-md {
        &:extend(.title-md);
    }
    &.fw-600 {
        font-weight: 600;
    }

    &.fz {
        &-16 {
            font-size: 1rem;
        }
    }
}

h1.ant-typography {
    .mobile({
        font-size: 2.25rem;
    });
}

.ant-typography {
    .mobile({
        font-size: @font-size-sm;
    });
}

// HELPERS
.fw {
    &-500 {
        font-weight: 500;
    }
    &-600 {
        font-weight: 600;
    }
}

.link-reset-password {
    color: @primary-color;
    font-weight: 600;
    font-size: @font-size-sm;
    margin-bottom: 1.5rem;
    display: inline-block;
}

.text-xs {
    font-size: 0.75rem;
}

.text-sm {
    font-size: @font-size-sm;
}

.title-md {
    font-size: 2.25rem;
}
