.ant-notification {
    &-notice {
        box-shadow: none;
        border-radius: 0.5rem;
        padding: 1rem;
        &-with-icon {
            display: flex;
            flex-direction: column;
            .ant-notification-notice-description {
                margin: 0;
                font-size: @font-size-sm;
            }
        }
        &-icon {
            position: static;
            margin: 0;
            display: inline-block;
            line-height: 0;
        }
        &-close {
            top: 1.25rem;
            right: 1.25rem;
            display: inline-block;
            line-height: 0;
        }
        .ant-notification-notice-message {
            margin: 0.75rem 0 0;
            padding: 0;
            font-size: @font-size-sm;
            font-weight: 500;
            line-height: 1.25rem;
            & ~ .ant-notification-notice-description:not(:empty) {
                margin-top: 0.75rem;
            }
        }
        &-success {
            color: @success-600;
            border: 1px solid @success-300;
            background: @success-25;
            .ant-notification-notice {
                &-message,
                &-close {
                    color: @success-700;
                }
            }
        }
        &-error {
            color: @error-600;
            border: 1px solid @error-300;
            background: @error-25;
            .ant-notification-notice {
                &-message,
                &-close {
                    color: @error-700;
                }
            }
        }
    }
}
