// DEFAULT
.ant-btn {
    font-size: 0.875rem;
    padding: 0.5rem 1rem;
    height: auto;
    line-height: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    &.ant-btn-icon-only {
        width: 3rem;
        height: 3rem;
        .svg.left {
            margin: 0;
        }
    }
    &.ant-btn-lg {
        padding: 0 1.75rem;
        font-size: 1.125rem;
        height: 3.75rem;
    }
    .svg {
        &:not(&.circle-secondary) {
            display: inline-block;
        }
        &.left {
            margin-right: 0.875rem;
        }
        &.right {
            margin-left: 0.875rem;
        }
    }

    &-default {
        &:hover,
        &:focus {
            color: @orange;
            border-color: @orange;
            background: transparent;
        }
    }
    &-primary {
        text-shadow: none;
        &:disabled {
            background: @primary-200;
            border-color: @primary-200;
            color: @white;
            &:hover {
                background: @primary-200;
                border-color: @primary-200;
                color: @white;
            }
        }
        &.ant-btn-sm {
            border-radius: 0.5rem;
            padding: 0.5rem 0.875rem;
            height: auto;
            line-height: normal;
        }
    }
    &-link {
        border-radius: 0.5rem;
        padding: 0;
        border: none;
        color: @primary-color;
        height: auto;

        &:hover {
            color: @primary-800;
        }
    }
    &-text {
        color: @primary-color;
        padding: 0;
        height: auto;
        &:hover {
            background: transparent;
            color: @primary-800;
        }
    }
    &-ghost {
        background: @white;
        color: @gray-700;
        box-shadow: @main-shadow;
        border: 1px solid @gray-300;
        &:active,
        &:focus {
            color: @gray-700;
            border-color: @gray-300;
            box-shadow: @shadow-focused;
        }
        &:hover {
            background: @gray-50;
            color: @gray-700;
            border-color: @gray-300;
        }
        &:disabled {
            opacity: 0.5;
            color: @gray-700;
            background: @white;
            &:hover {
                color: @gray-700;
                background: @white;
            }
        }
    }

    &-dangerous {
        background: @error-600;
        color: @white;
        border-color: @error-600;
        box-shadow: @main-shadow;
        &:hover {
            background: @error-700;
            border-color: @error-700;
            color: @white;
        }
        &:focus {
            background: @error-600;
            border-color: @error-600;
            color: @white;
            box-shadow: @main-shadow, 0 0 0 4px @error-100;
        }
        &.ant-btn-primary {
            background: @error-600;
            color: @white;
            border-color: @error-600;
            box-shadow: @main-shadow;
            text-shadow: none;
            &:hover {
                background: @error-700;
                border-color: @error-700;
                color: @white;
            }
            &:focus {
                background: @error-600;
                border-color: @error-600;
                color: @white;
                box-shadow: @main-shadow, 0 0 0 4px @error-100;
            }
        }
    }

    &.btn-save {
        border-radius: 2rem;
    }
}

.ant-modal-close {
    top: 1rem;
    right: 1rem;
}

.ant-upload {
    &.ant-upload-drag {
        background: none;
        border: none;
        .ant-upload {
            padding: 0;
            display: block;
            .ant-upload-drag-container {
                display: block;
            }
        }
        &-hover {
            .ant-btn {
                background: @tertiary-color;
                border-color: @tertiary-color;
                color: @primary-color;
            }
        }
        &.ant-upload-disabled {
            opacity: 0.7;
        }
    }
    &-list {
        .ant-upload-list-item {
            padding: 1rem;
            border: 1px solid @gray-200;
            margin-top: 1rem;
            height: auto;
            display: flex;
            align-items: flex-start;
            border-radius: 0.5rem;
            &:hover {
                .ant-upload-list-item-info {
                    background: @white;
                }
            }
            &.done {
                border-color: @primary-color;
            }
            button {
                color: @gray-500;
                background: none;
                border: none;
                display: block;
                padding: 0;
                line-height: 0;
                cursor: pointer;
            }
            .circle-primary {
                width: 2.25rem;
                height: 2.25rem;
                border-width: 4px;
                .svg {
                    height: 13px;
                }
            }
            &-info {
                flex: 1;
                padding: 0 0.25rem 0 0.875rem;
            }
        }
    }
    &-success {
        line-height: 0;
        background: @primary-color;
        color: @white;
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .ant-btn {
        border-radius: 0.5rem;
    }
    .btn-upload {
        background: none;
        border: 1px solid @gray-200;
        color: @gray-500;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1rem 1.5rem;
        border-radius: 0.5rem;
        font-size: @font-size-sm;
        .svg {
            display: flex;
            margin: 0;
        }
    }
    b {
        font-weight: 500;
    }
}

// CUSTOM BTN
.btn {
    &-close {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        padding: 0;
        width: 2.5rem;
        height: 2.5rem;
        background: @tertiary-color;
        line-height: normal;
        font-size: 0.75rem;
    }

    &-save.ant-btn {
        background-color: @success;
        border-color: @success;
        color: @white;
        &:hover,
        &:focus,
        &:active {
            background: transparent;
            color: @success;
            border-color: @success;
        }
        &:disabled {
            background: @success;
            border-color: @success;
            color: @white;
            &:hover {
                background: @success;
                border-color: @success;
                color: @white;
            }
        }
    }

    &-logout {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &-success {
        background: @success-600;
        border-color: @success-600;

        &:disabled {
            background: @success-600;
            border-color: @success-600;
            opacity: 1;

            &:hover,
            &:focus,
            &:active {
                opacity: 1;
                background: @success-600;
                border-color: @success-600;
            }
        }
    }
    &-large {
        width: 100%;
    }
    &-error {
        color: @error;
        background: @white;
        border: 1px solid @error-300;
        line-height: 1.25rem;
        padding: 0.625rem 1rem;
        &:hover,
        &:focus {
            background: @error-50;
            color: @error;
            border-color: @error-300;
        }
    }
}

// ANT SWITCH
.ant-switch {
    height: 1.25rem;
    min-width: 2.25rem;
    background: @gray-100;
    &:focus {
        box-shadow: none;
    }
    &-handle {
        width: 1rem;
        height: 1rem;
        &::before {
            box-shadow: @secondary-shadow;
        }
    }
    &-checked {
        background: @primary-color;
        .ant-switch-handle {
            left: calc(100% - 1rem - 2px);
        }
    }
}
