// SPACING
each(range(15), {
    .my-@{value} {
      margin-top: (@value * 0.25rem);
      margin-bottom: (@value * 0.25rem);
    }
    .mt-@{value} {
      margin-top: (@value * 0.25rem);
    }
    .mb-@{value} {
        margin-bottom: (@value * 0.25rem);
    }
    .mr-@{value} {
        margin-right: (@value * 0.25rem);
    }
    .ml-@{value} {
        margin-left: (@value * 0.25rem);
    }
    .pt-@{value} {
        padding-top: (@value * 0.25rem);
    }
    .pb-@{value} {
        padding-bottom: (@value * 0.25rem);
    }
    .pr-@{value} {
        padding-right: (@value * 0.25rem);
    }
    .pl-@{value} {
        padding-left: (@value * 0.25rem);
    }
    .px-@{value} {
        padding-right: (@value * 0.25rem);
        padding-left: (@value * 0.25rem);
    }
    .my-sm-@{value} {
        .mobile({
            margin: (@value * 0.25rem) 0;
        })
    }
    .mt-sm-@{value} {
        .mobile({
            margin: (@value * 0.25rem) 0;
        })
    }
});

// RESPONSIVE
@breakpoint-lg: 1200px;
@breakpoint-md: 991px;
@breakpoint-sm: 767px;

.desktop(@rules) {
    @media screen and (max-width: @breakpoint-lg) {
        @rules();
    }
}

.tablet(@rules) {
    @media screen and (max-width: @breakpoint-md) {
        @rules();
    }
}

.mobile(@rules) {
    @media screen and (max-width: @breakpoint-sm) {
        @rules();
    }
}
