.ant-table {
    color: @gray-500;
    &-wrapper {
        border: 1px solid @gray-200;
        box-shadow: @secondary-shadow;
        border-radius: 0.5rem;
    }
    &-cell {
        b {
            font-weight: 500;
            color: @gray-900;
        }
        &::before {
            display: none;
        }
    }
    &-row {
        cursor: pointer;
        position: relative;
    }
    &-thead {
        .ant-table-cell {
            background: @gray-25;
            color: @gray-500;
            font-size: 0.75rem;
            padding-top: 0.75rem;
            padding-bottom: 0.75rem;
        }
        .ant-table-column-title {
            color: @gray-500;
        }
    }
    &-tbody {
        .ant-table-cell {
            padding: 0 1.5rem;
            height: 4.5rem;
            &.ant-table-row-expand-icon-cell {
                padding: 0 0 0 1rem;
            }
        }
    }
    &-pagination {
        justify-content: center;
        align-items: center;
        margin: 0.875rem 0;
        .ant-pagination-item {
            border-radius: 0.5rem;
            min-width: 2.5rem;
            height: 2.5rem;
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: 0.3s ease-in-out;
            &-active {
                background: @primary-50;
            }
            a {
                transition: 0.3s ease-in-out;
            }
        }
        .ant-btn-ghost {
            display: flex;
            align-items: center;
            line-height: 1.25rem;
        }
        .ant-pagination-prev {
            margin-right: auto;
            margin-left: 1.5rem;
            height: auto;
            &:hover {
                button {
                    border-color: @gray-300;
                }
            }
            .ant-btn {
                padding-left: 1.25rem;
            }
        }
        .ant-pagination-next {
            margin-left: auto;
            margin-right: 1.5rem;
            height: auto;
            &:hover {
                button {
                    border-color: @gray-300;
                }
            }
            .ant-btn {
                padding-right: 1.25rem;
            }
        }
        .ant-pagination-options {
            margin-right: 1.5rem;
            margin-left: 0;
            height: auto;
            .ant-select {
                line-height: normal;
                .ant-select-selector {
                    border-color: @gray-300;
                    box-shadow: @main-shadow;
                    border-radius: 0.5rem;
                    background: @white;
                    height: auto;
                    padding: 0.5rem 0.875rem;
                    &:active,
                    &:focus {
                        color: @gray-700;
                        border-color: @gray-300;
                        box-shadow: @shadow-focused;
                    }
                    &:hover {
                        background: @gray-50;
                        color: @gray-700;
                        border-color: @gray-300;
                    }
                    &:disabled {
                        opacity: 0.5;
                        color: @gray-700;
                        background: @white;
                        &:hover {
                            color: @gray-700;
                            background: @white;
                        }
                    }

                    &::after {
                        line-height: normal;
                    }

                    .ant-select-selection-item {
                        font-size: 0.875rem;
                        font-weight: 500;
                        padding-right: 1.5rem;
                        line-height: normal;
                    }
                }
                .ant-select-arrow {
                    color: @gray-700;
                }
            }
        }
    }
    &-row-expand-icon {
        .mobile({
            transform: unset;
            line-height: normal;
            width: 2rem;
            height: 2rem;
        });
    }
}

.table-selection {
    max-height: 11.875rem;
    overflow-y: auto;
    .ant-table-thead {
        position: sticky;
        top: 0;
        z-index: 1;
    }
    .ant-table-tbody {
        .ant-table-row .ant-table-cell:first-child {
            position: relative;
            padding-left: 3.5rem;
            &::before {
                content: '';
                width: 1.25rem;
                height: 1.25rem;
                background: @white;
                border: 1px solid @gray-300;
                display: block;
                border-radius: 50%;
                position: absolute;
                top: 50%;
                left: 1.5rem;
                transform: translateY(-50%);
                transition: 0.3s;
            }
            &::after {
                content: '';
                height: 0.5rem;
                width: 0.5rem;
                background: @primary-color;
                display: block;
                border-radius: 50%;
                position: absolute;
                top: 50%;
                left: 1.875rem;
                transform: translateY(-50%);
                opacity: 0;
                transition: 0.3s;
            }
        }
        .row-selected {
            .ant-table-cell:first-child {
                &::before {
                    background: @primary-50;
                    border-color: @primary-color;
                }
                &::after {
                    opacity: 1;
                }
            }
        }
    }
}
