.ant-modal {
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .modal-container {
        padding: 1.5rem;
    }
    &-content {
        border-radius: 0.75rem;
        width: auto;
        min-width: 25rem;
        max-width: 37.5rem;
        box-shadow: @secondary-shadow;
    }
    &-close-x {
        width: auto;
        height: auto;
    }
    &-body {
        padding: 0;
    }
    &-footer {
        padding: 1rem;
        .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
            margin-left: 1rem;
        }
    }
    &-icon {
        margin-bottom: 1.25rem;
    }
    .ant-form {
        margin-top: 1.25rem;
        &-item {
            margin-bottom: 0.75rem;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .ant-radio-inner {
        border-color: @primary-color;
        background-color: white;
    }

    .mobile({
        margin: 0 auto;
        &-body {
            padding: 1.5rem 0 0;
            .px-10 {
                padding: 0 3.5rem 0 1.5rem;
            }
        }
    });
}

.modal {
    &-action {
        width: 585px;
        // .form-footer {
        //     padding: 1rem;
        // }
    }
}

.ant-modal-confirm-title {
    font-size: 1.5rem !important;
}

.ant-modal-confirm-btns {
    display: flex;
    flex-direction: row;
    align-items: center;
}
