body {
    margin: 0;

    h1,
    h2,
    h3,
    h4,
    p {
        margin: 0;
    }

    ul {
        list-style: none;
        padding: 0;
    }

    b {
        font-weight: 600;
    }

    a {
        font-size: @font-size-sm;
        font-weight: 500;
        &:hover {
            color: @primary-800;
        }
    }
}

.svg {
    display: inline-block;
    line-height: 0;
    span {
        display: inline-block;
    }
}

// GENERAL CLASSES
.container {
    padding-left: 7rem;
    padding-right: 7rem;
    .mobile({
        padding-left: 1rem;
        padding-right: 1rem;
    });
}

.row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    &.align-start {
        align-items: flex-start;
    }
    &.between {
        justify-content: space-between;
    }
    &-info {
        *:not(:last-child) {
            display: flex;
            align-items: center;
            &::after {
                content: '';
                width: 3px;
                height: 3px;
                background: @gray-500;
                border-radius: 50%;
                display: inline-block;
                margin: 0 0.5rem;
            }
        }
    }
}

.col {
    display: flex;
    flex-direction: column;
}

.center {
    text-align: center;
}

.justify {
    text-align: justify;
}

.mobile {
    margin: auto;
    max-width: 500px;
}

.hidden {
    display: none;
}

.info {
    &-card.ant-card {
        .ant-card-body {
            display: flex;
            align-items: center;
            padding-right: 5rem;
        }
    }
    &-item {
        &:not(:last-child) {
            margin-right: 1.5rem;
            .mobile({
                margin: 0 0 1rem;
            });
        }

        .mobile({
            width: 100%;
        });
    }
    &-icon {
        background: @tertiary-color;
        border-radius: 50%;
        width: 2.5rem;
        height: 2.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.auth {
    &-container {
        height: 100vh;
        display: flex;
        flex-direction: column;
        background: url('/assets/background-pattern.svg') no-repeat center center;
        background-size: cover;
    }
    &-content {
        flex: 1;
        display: flex;
        align-items: center;
        width: 100%;
    }
    &-logo {
        margin-top: 2rem;
        margin-left: 2rem;
        svg {
            width: 100%;
            height: 100%;
        }
    }
}

.loader {
    &.absolute {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

// COLORS
.c {
    &-primary {
        color: @primary-color;
    }
    &-gray-900 {
        color: @gray-900;
    }
    &-gray-700 {
        color: @gray-700;
    }
    &-gray-500 {
        color: @gray-500;
    }
}

.bg {
    &-gray-50 {
        background: @gray-50;
    }
    &-primary-50 {
        background: @primary-50;
    }
}

// CIRCLE ICON
.circle {
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    .mobile({
      width: 2.5rem;  
      height: 2.5rem;
    });
    &-primary {
        background: @primary-100;
        border: 8px solid @primary-50;
        color: @primary-color;
        &:extend(.circle);
        svg {
            .mobile({
                width: 1rem;
                height: 1rem;
            });
        }
    }
    &-secondary {
        background: @gray-100;
        border: 8px solid @gray-50;
        color: @gray-600;
        &:extend(.circle);
        svg {
            .mobile({
                width: 1rem;
                height: 1rem;
            });
        }
    }
    &-error {
        background: @error-100;
        border: 8px solid @error-50;
        color: @error-600;
        &:extend(.circle);
        svg {
            .mobile({
                width: 1rem;
                height: 1rem;
            });
        }
    }
}

// SEPARATOR
.separator {
    background: @gray-200;
    width: 100%;
    height: 1px;
    display: block;
    margin: 1.25rem 0;
    position: relative;
    z-index: -1;
}
