.ant-list.ant-list-split {
    padding: 1.5rem;
    border-radius: 0.5rem;
    border: 1px solid @gray-200;
    box-shadow: @secondary-shadow;
    margin-bottom: 0.5rem;
    .ant-list-header {
        padding-top: 0;
        border-color: @gray-200;
        padding-bottom: 1.5rem;
    }
    .ant-list-footer {
        padding: 0;
    }
    .ant-spin-container .ant-list-items {
        li.ant-list-item {
            padding: 1.5rem 0;
            border-color: @gray-200;
            .row {
                width: 100%;
            }
            &:last-child {
                border-bottom: none;
                padding-bottom: 0;
            }
        }
    }
}
